@import '../../../styles/customMediaQueries.css';

:root {
  --filtersHeight: 107px;
  --marginHeight: 12px;
}

.root {
  flex-direction: column;
}

.listingCardsMapVariant {
  display: grid;
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    display: block;
    padding: 0 0 24px 0;
  }
}

.ikImage {
  border-radius: var(--borderRadiusLarge);
  height: 100%;
  width: auto;
}

.imageWrapper {
  align-items: flex-start;
  border-radius: var(--borderRadiusLarge);
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  width: auto;
}

.mobileCards {
  border-radius: var(--borderRadiusLarge);
  margin-bottom: 6px;
  max-height: calc(
    var(--vh, 1vh) * 100 - var(--topbarHeight) - var(--filtersHeight) - var(--marginHeight)
  );
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.mobileCards .listingWrapper {
  margin: 6px 0;
  scroll-snap-align: center;
}

.listingLink {
  border-radius: var(--borderRadiusLarge);
}

.virtual {
  background: var(--colorAlertBlue);
  border-radius: 1rem 0 1rem 0;
  left: 0;
  padding: 18px;
  position: absolute;
  top: 0;
}

.virtual svg {
  fill: none;
  stroke: var(--colorWhite);
}

.info {
  background: var(--colorForeground);
  border-radius: var(--borderRadiusLarge);
  color: var(--colorWhite);
  bottom: 6px;
  left: 0;
  margin: auto;
  right: 0;
  padding: 12px 12px;
  position: absolute;
  max-width: calc(100% - 18px);

  @media (--viewportLarge) {
    max-width: calc(100% - 36px);
  }
}

.info p {
  padding: 0;
}

.info .title {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 16px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.info .name {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 16px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.price {
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
}

.photoAlbum {
  width: 100%;
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.listingCard {
  width: 100%;
}

.pagination {
  background-color: transparent;
  margin: auto 12px 60px;
  scroll-snap-align: start;
}
