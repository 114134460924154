@import '../../../styles/customMediaQueries.css';

.noSearchResults {
  composes: h4 from global;
  border-radius: var(--borderRadiusLarge);
  border: 2px dashed var(--colorDarkBeige);
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 24px;

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 24px;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.noSearchResults svg {
  fill: none;
  height: 24px;
  margin-bottom: 18px;
  width: 24px;
}

.createListingLink,
.resetAllFiltersButton {
  composes: h5 from global;
  color: var(--colorForeground);
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 16px;
  text-decoration-line: underline;
  margin-top: 24px;

  /* Layout */
  display: inline;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
    text-decoration-line: none;
  }
}
